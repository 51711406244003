.support-left-section {
	position: sticky;
	background-color: $cool-gray-90;

	.support-versions-section {
		padding-top: 0.1rem;
	}

	.ibp-support {
		padding: 1rem;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.125rem;
		letter-spacing: 0.16px;
		font-weight: 400;
	}

	.ibp-support2 {
		padding-top: 1rem;
		padding-left: 1rem;
		padding-bottom: 1rem;
		padding-right: 1rem;
		font-size: inherit;
		line-height: 1.5rem;
		border-top: 2px solid $background;
	}
}

.support-contact-section {
	.support-contact-button {
		@include type-style('body-short-02');
		height: 5rem;
		width: 100%;
	}

	padding-top: 3rem;

	// .cds--btn--secondary
	.ibp__add--item--button-with-text {
		max-width: 100%;
		width: 100%;
	}

	// .cds--btn--secondary
	.ibp__add--item--button-with-text.support-button-label {
		height: 100%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-align: left;
		white-space: normal;
		width: 100%;
	}
}
