.ibp-side-panel-error {
	background-color: $inverseBackground;
	color: $inverseText;
	z-index: $sidePanelError;

	// .cds--inline-notification {
	// 	max-width: 100%;
	// }

	.cds--actionable-notification {
		max-width: 100%;
	}

	.cds--inline-notification__text-wrapper {
		display: block;
	}

	.cds--inline-notification__title {
		margin-bottom: 1rem;
	}

	.ibp-link {
		background-color: transparent;
		border-top: none;
		border-left: none;
		border-right: none;
		color: $action;
		cursor: pointer;
		font-size: 100%;
		margin-bottom: 0.25rem;
		margin-top: 0px;
		padding: 0px;
	}

	.cds--inline-notification {
		margin: 0;
		padding: 1rem 1rem 1.75rem 1rem;
	}

	.cds--actionable-notification--error {
		margin: 0;
		padding: 1rem 1rem 1.75rem 1rem;
	}

	.cds--inline-notification--error {
		border: 0;
		border-top: 0.25rem solid $field-error;
	}

	.cds--inline-notification__title {
		@include font-weight('semibold');

	}

	.cds--inline-notification__title,
	.cds--inline-notification__subtitle {
		color: $inverseText;
	}

	.cds--inline-notification__title {
		margin-bottom: 0.5rem;
	}

	.cds--inline-notification__details {
		align-items: flex-start;
	}

	.ibp-side-panel-error-details {
		max-height: 10rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	a.ibp-break-error-link {
		font-size: 12px;
		margin-bottom: 1.5rem;
		display: block;
	}
}

.ibp-large-panel {
	.ibp-side-panel-error {
		position: absolute;
		bottom: 4rem;
		right: 0;
		width: 31rem;
	}
}
