.ibp-config-override {
	margin-top: 1rem;

	.cds--text-area {
		background-color: $cool-gray-90;
	}

	.cds--form-item {
		display: block;
	}
}
