.ibp-important-box-container {
	color: $text;
	width: 100%;

	.ibp-important-label {
		@include type-style('label-01');
		color: $warning;
		margin-bottom: 0.5rem;
		margin-top: 1rem;
	}

	.ibp-important-box {
		border: 1px solid $warning;
		margin-bottom: 1rem;
		padding: 1rem;

		.ibp-important-link {
			@include type-style('body-short-01');
			display: block;
			margin-top: 1rem;
			display: block;
			width: max-content;
		}

		.ibp-important-text {
			@include type-style('body-short-01');
		}
	}

	&.ibp-important-box-container-informational {
		.ibp-important-label {
			color: $blue-40;
		}

		.ibp-important-box {
			border: 1px solid $blue-40;
		}
	}

	&.ibp-important-box-container-strict {
		.ibp-important-label {
			color: $red-50;
		}

		.ibp-important-box {
			border: 1px solid $red-50;
		}
	}
}
