.ibm {
	.cds--overflow-menu-options {
		width: 12rem;
	}

	.cds--tooltip__trigger.cds--tooltip__trigger--definition+.cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top:after,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top:focus+.cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top:focus .cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top:hover+.cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top:hover .cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom:after,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom:focus+.cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom:focus .cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom:hover+.cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom:hover .cds--assistive-text {
		max-width: 20rem;
	}

	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top+.cds--assistive-text,
	.cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom+.cds--assistive-text {
		min-width: 20rem;
	}
}

body #teconsent {
	display: none !important;
}

.cds--toggle__switch {
	white-space: nowrap;
}
