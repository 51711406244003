.ibp-node-orderer-tile-name,
.ibp-node-orderer-tile-msp {
	@include type-style('body-short-01');
	@include font-weight('regular');
	color: $cool-gray-30;
}

.ibp-node-orderer-tile-msp {
	@include singleLineEllipsis;
	padding-top: 0.5rem;
}

.ipb-node-orderer-tile-cloud-type {
	@include font-weight('semibold');
	bottom: 1rem;
	color: $cool-gray-30;
	position: absolute;
	font-size: 0.875rem;
}

.ibp-requires-attention {
	width: 100%;

	p {
		font-style: italic;
		font-size: 0.875rem;
	}

	.ibp-notification-icon {
		position: absolute;
		top: 0;
		right: 2rem;
	}
}
