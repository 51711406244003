.ibp-page-header {
	padding: 1rem 0 0 0;
	padding-bottom: 2rem;

	.ibp-page-header-tooltip {
		.ibp--tooltip__label {
			@include type-style('productive-heading-05');
		}
	}
}

.ibp-iks-support-with-link {
	font-size: 0.875rem;
	letter-spacing: 0.01rem;
	line-height: 1.125rem;
	margin-top: 0.5rem;
}

.threat-style {
	background: #000 !important;
	.cds--inline-notification__text-wrapper {
		color: #cc0000;
		animation: color-change 3s infinite;
	}
}

@keyframes color-change {
	0% {
		color: #cc0000;
		font-weight: 900;
	}

	50% {
		color: #fff;
		font-weight: 300;
	}

	100% {
		color: #cc0000;
		font-weight: 900;
	}
}
