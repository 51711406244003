$feature-flags: (
	grid-columns-16: true,
);

$use-flexbox-grid: true;

// @import '@carbon/themes/scss/themes';
// @import '@carbon/themes/scss/theme';
@import './assets/sass/imports';
// @import '@carbon/colors';
@import './assets/sass/carbonTheme';
$prefix: 'carbon';
* {
	@include theme($g100);
	// font-family: ('IBM-Plex-Mono'), ('IBM-Plex-Sans');
}

// import Carbon styles here
@import './assets/sass/ibm_flex';


$better_disabled_text_color: #505456;

* {
	font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
	font-size: 100%;
}

html {
	@include type-style('body-short-02');
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
}

h1 {
	@include type-style('productive-heading-05');
}

h2 {
	@include type-style('productive-heading-04');
}

h3 {
	@include type-style('productive-heading-03');
}

h4 {
	@include type-style('heading-02');
}

h5 {
	@include type-style('heading-01');
}

p,
button,
input {
	@include type-style('body-short-02');
	// @include font-face-sans();

}

.ibp-text-label,
small {
	@include type-style('body-short-01');
}

#root {
	height: 100%;
}

// carbon overrides

.cds--btn--tertiary {

	&:hover,
	&:focus {
		color: $inverseText;
	}
}

.cds--skeleton__placeholder,
.cds--skeleton__text {
	background-color: $cool-gray-70;
}

// Set correct hover color of data table rows
.cds--data-table {
	th {
		background-color: $cool-gray-80;

		&.cds--table-column-checkbox {
			background-color: $cool-gray-80;
		}
	}

	td {
		background-color: $cool-gray-90;
	}

	tbody {
		tr {
			td {
				padding-bottom: 0;
			}

			&:hover {
				td {
					background: $cool-gray-80;
				}
			}
		}
	}
}

.cds--text-input {
	&::placeholder {
		color: $better_disabled_text_color;
	}
}

.cds--text-input,
.cds--dropdown,
.cds--pagination {
	background-color: $cool-gray-90;
}

.cds--pagination {
	.cds--select__page-number {
		.cds--select-input {
			border-left: 0;
		}

		.cds--select-input {
			&:hover {
				background-color: $cool-gray-90;
			}
		}
	}
}

.cds--dropdown {
	&:hover {
		background-color: $cool-gray-80;
	}

	.cds--list-box__menu-item--highlighted,
	.cds--list-box__menu {
		background-color: $cool-gray-90;
	}
}

.cds--data-table-container {
	overflow: visible;
}

.cds--overflow-menu-options::before {
	display: none;
}
/* interferes with overflow menu when button is disabled, seems like we dont' need it, removed
.cds--overflow-menu-options .cds--overflow-menu-options__btn {
	color: $text;
}
*/
.cds--overflow-sdk-item-btn {
	height: 2.95rem;
}

.cds--tabs {
	// border-bottom: 1px solid $cool-gray-70;

	.cds--tab--overflow-nav-button {
		display: none;
	}

	.cds--tabs__nav {
		.cds--tabs__nav-item.cds--tabs__nav-item--selected {
			@include font-weight('semibold');
			border-bottom: 2px solid $action;

			.cds--tabs__nav-link {
				color: $white;
				padding: 0.75rem 0.75rem 0.75rem 1rem;

				&:hover,
				&:focus {
					border-bottom: 0;
					box-shadow: none;
				}
			}
		}

		.cds--tabs__nav-item {
			@include font-weight('regular');
			align-items: flex-end;
			display: flex;
			padding: 0;

			.cds--tabs__nav-link {
				padding: 0.75rem 0.75rem 0.75rem 1rem;
			}

			&:not(.cds--tabs__nav-item--selected) {
				.cds--tabs__nav-link {
					border-bottom: 2px solid transparent;

					&:hover {
						border-bottom: 2px solid $white;
						box-shadow: none;
						color: $white;
					}
				}
			}
		}

		.cds--tabs__nav-item+.cds--tabs__nav-item {
			margin-left: 0;
		}
	}
}

.cds--tabs__nav-item:hover .cds--tabs__nav-link {
	color: white;
}

.cds--tooltip__trigger {

	&:hover path,
	&:focus path {
		fill: $cool-gray-40;
	}

	path,
	polygon,
	circle {
		fill: $cool-gray-30;
	}
}

.cds--toggle__label {
	margin-top: 0;
	margin-right: 1rem;
}

.cds--toggle__check {
	display: none;
}

.cds--toggle--small:checked+.cds--toggle__label .cds--toggle__appearance:before,
.cds--toggle:checked+.cds--toggle__label .cds--toggle__appearance:after {
	background-color: $cool-gray-70;
	border-color: $cool-gray-70;
	margin-top: 0.25rem;
}

.cds--toggle--small:checked+.cds--toggle__label .cds--toggle__appearance:after {
	background-color: $green-30;
	border-color: $green-30;
	margin-left: 0px;
	-webkit-transform: translateX(17px);
	transform: translateX(17px);
}

.cds--toggle--small:focus+.cds--toggle__label .cds--toggle__appearance:before {
	box-shadow: 0 0 0 3px $cool-gray-40;
	outline: 1px solid transparent;
}

.cds--tooltip {
	@include type-style('body-short-01');
	// min-width: 15rem;

	.cds--tooltip__caret {
		background: transparent;
		// left: 10rem;
	}

	&.cds--tooltip--shown {
		background-color: $cool-gray-10;
		color: $inverseText;
		line-height: 1.45;
	}
}

.cds--dropdown__wrapper {
	min-width: 10rem;

	.cds--list-box__menu-item.cds--list-box__menu-item--highlighted {
		&:hover {
			background-color: $cool-gray-80;
		}
	}
}

.cds--tooltip__trigger {
	align-items: flex-end;
}

.ibp-error-panel {
	width: 100%;

	.cds--inline-notification--info {
		border: none;
		border-left: 6px solid #ffcc00;
		color: black;
		margin: 1.5rem 0 0 0;

		.cds--inline-notification__icon {
			fill: #ffcc00;
		}
	}

	.cds--inline-notification__details {

		.cds--inline-notification__title,
		.cds--inline-notification__subtitle {
			color: $inverseText;
		}
	}

	.cds--inline-notification--error {
		color: black;
		margin-bottom: 2rem;
	}
}

.cds--snippet--inline {
	background-color: $cool-gray-10 !important;
	color: $inverseText;

	&:hover {
		background-color: $cool-gray-30 !important;
	}

	&:focus {
		border: 2px solid $action;
		outline: none;
	}
}

.cds--snippet--inline.cds--btn {
	padding: 0;

	&:hover {
		color: $inverseText;
	}
}

.cds--toast-notification {

	.cds--toast-notification__close-icon,
	.cds--toast-notification__icon {
		fill: $cool-gray-80;
	}
}

// Link styling

.ibp-link {
	border-bottom: 1px solid transparent;
	text-decoration: none;
	color: $blue-40;
	display: inline-block;

	&:hover {
		border-bottom: 1px solid $blue-40;
		display: inline-block;
	}
}

.ibp-button-link {
	background: transparent;
	border: 0;
	border-bottom: 1px solid transparent;
	padding: 0;

	& * {
		color: $blue-40;
	}

	&:hover {
		border-bottom: 1px solid $blue-40;
		display: inline-block;
	}
}

.ibm .cds--data-table .cds--overflow-menu {
	height: 2.75rem;
}

.ibp-node-name-removal {
	background-color: $cool-gray-20;
	border-radius: 4px;
	display: inline-block;
	margin-top: 1rem;
	padding: 0.125rem 0.25rem;
}

.ibp-hover-text {
	background-color: $white;
	color: $inverseText;
	display: block;
	top: -3rem;

	.cds--tooltip__caret {
		left: 3rem;
		right: auto;
	}
}

.ibp-container-cell-item {
	.ibp-hover-text {
		top: auto;
		bottom: 2rem;
	}
}

.ibp-tooltip-wrap {
	align-items: flex-end;
	display: flex;
}

.ibp-node-status-container {
	.ibp-node-status {
		height: 1.25rem;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateY(0);
		width: 1.25rem;

		&:hover+.ibp-node-status-label,
		&:focus+.ibp-node-status-label {
			display: block;
			opacity: 1;
			transform: translateY(-2.125rem);
		}
	}

	&:focus {
		.ibp-node-status-label {
			display: block;
			opacity: 1;
			transform: translateY(-2.125rem);
		}
	}

	.ibp-node-status.ibp-node-status-skeleton {
		animation: skeletonShimmer 600ms linear infinite forwards alternate;
		background-color: $cool-gray-70;
	}

	.ibp-node-status-label {
		@include type-style('body-short-01');
		background-color: #fff;
		color: $inverseText;
		padding: 0.5rem;
		position: absolute;
		top: 0;
		transition: all 0.25s ease;
		right: 0;
		opacity: 0;
		display: none;
	}

	.ibp-node-status-running {
		background-color: $success;
	}

	.ibp-node-status-running-partial {
		background-color: $warning;
	}

	.ibp-node-status-stopped {
		background: repeating-linear-gradient(135deg, $stopped, $stopped 2px, $stoppedSecondary 2px, $stoppedSecondary 4px);
	}

	.ibp-node-status-unretrievable {
		background: repeating-linear-gradient(135deg, $warning, $warning 2px, $warningSecondary 2px, $warningSecondary 4px);
	}

	.ibp-node-status-unknown {
		background-color: $cool-gray-40;
	}
}

.ibp-table-status {
	border: 1px solid $cool-gray-40;
	border-radius: 4px;
	display: inline-block;
	height: 8px;
	margin-right: 0.5rem;
	width: 8px;
}

.ibp-table-status-running {
	background-color: $green-40;
	border-color: $green-40;
}

.ibp-table-status-running_partial {
	background-color: $warning;
	border-color: $warning;
}

.ibp-table-status-stopped {
	background-color: $red-50;
	border-color: $red-50;
}

.ibp-table-status-unretrievable {
	background: $warning;
}

.ibp-table-status-unknown,
.ibp-table-status-status-undetected {
	background-color: $cool-gray-40;
}

.ibp-inline--tooltip-trigger {
	margin: 0;

	.cds--tooltip__trigger {
		align-items: center;
		display: flex;
		height: 1.25rem;
		justify-content: center;
		margin: 0;
		width: 1.25rem;

		svg {
			height: 1.25rem;
			width: 1.25rem;

			path {
				fill: $white;
			}
		}
	}
}

.cds--tooltip.cds--tooltip--shown.ibp-inline--tooltip {
	color: $cool-gray-80;
	background-color: $white;
	min-width: auto;
	padding: 0.5rem 1rem;
	text-align: center;
	width: auto;

	.cds--tooltip__caret {
		left: 0;
	}
}

.cds--btn--danger {
	border-color: $red-60;
}

.summary-label {
	display: inline-block;
	padding-right: 2.5rem;
	position: relative;
}

.ibp-patch-available-tab {
	.cds--tabs__nav-item-label {
		text-align: center;
	}
	.cds--tabs__nav-link {
		width: auto;

		span {
			position: relative;
			top: -0.125rem;
		}
	}
}

.ibp-detail-page-icon-button {
	justify-content: center;
	min-width: 2rem;
	max-width: 2rem;
}

.cds--text-input:disabled,
.cds--checkbox:disabled+.cds--checkbox-label,
.cds--checkbox-label[data-contained-checkbox-disabled='true'] {
	color: $cool-gray-30;
}

.cds--checkbox:checked:focus+.cds--checkbox-label::before {
	border-color: $action;
}

.ibp-orderer-details,
.ibp-ca-details,
.ibp-peer-details {
	.cds--col-lg-4 {
		padding-right: 0;
	}
}

.cds--overflow-menu {
	float: right;
}

.cds--accordion__item:not(.cds--accordion__item--active) {
	height: 2.25rem;
}

.cds--accordion__heading {
	height: inherit;
	padding: 0.5rem 0;
}

.ibp--svg-white-fill {
	fill: $white-0;
}

.ibm {
	.cds--loading {
		.cds--loading__svg {
			.cds--loading__background {
				stroke: $blue-40;
			}

			.cds--loading__stroke {
				stroke: $white-0;
			}
		}
	}
}

.ibp-third-party-ca-doc-link {
	display: block;
	margin-top: 1rem;
	width: max-content;
}

.ibp-third-party-ca-doc-link:hover {
	display: block;
}

.flex-d-column {
	flex-direction: column;
}

.cds--tooltip__trigger.cds--tooltip__trigger--definition {
	border-bottom-color: $blue-40;
}

#title_bar {
	z-index: $secondLayer;
}

.cds--text-input:disabled,
.cds--text-input[disabled] {
	background-color: #{$cool-gray-90}80;
	background-color: rgba(33, 39, 42, 0.5);
	// Carbon cool gray 90 with alpha, fallback for IE, which does not support alpha values w/ hex
	// We need this custom change until Carbon makes a disabled state that will pass color contrast a11y
}

.cds--tile--clickable,
.cds--tile--selectable,
.cds--tile--expandable,
.cds--tile {
	&:hover {
		background-color: $cool-gray-80;
	}
}

.cds--list-box--disabled .cds--list-box__label,
.cds--list-box--disabled.cds--list-box--inline .cds--list-box__label,
.cds--radio-button:disabled+.cds--radio-button__label {
	color: $better_disabled_text_color;
}

.cds--content-switcher-btn.cds--content-switcher--selected {
	&:focus {
		box-shadow: inset 0 0 0 2px $action;
	}
}

// override carbon, too dark
.cds--dropdown--disabled .cds--dropdown-text,
.cds--dropdown--disabled .cds--list-box__label {
	color: $better_disabled_text_color;
}

.ibp-main svg,
.ibp-fill-color,
.cds--tooltip__trigger path,
.cds--btn--secondary .cds--btn__icon path,
.cds--list-box__menu-icon>svg,
.cds--list-box__menu-item--active .cds--list-box__menu-item__selected-icon {
	fill: white;
}

@media screen and (-ms-high-contrast: black-on-white) {

	.ibp-main svg,
	.ibp-fill-color,
	.cds--tooltip__trigger path,
	.cds--btn--secondary .cds--btn__icon path,
	.cds--list-box__menu-icon>svg,
	.cds--list-box__menu-item--active .cds--list-box__menu-item__selected-icon {
		fill: black;
	}

	.cds--checkbox:disabled+.cds--checkbox-label,
	.cds--checkbox-label[data-contained-checkbox-disabled='true'] {
		opacity: 0.25;
	}

	.cds--dropdown--disabled {
		outline: none;
	}

	.ibp-timeline-disabled-step {
		opacity: 0.25;
	}

	.ibp-timeline-cancel-button:focus {
		.ibp-timeline-cancel-button-label {
			outline: 1px dotted;
		}
	}

	.cds--toggle-input+.cds--toggle-input__label>.cds--toggle__switch::before {
		background: linear-gradient(to top, $cool-gray-100, $cool-gray-90);
	}

	.cds--toggle-input:checked+.cds--toggle-input__label>.cds--toggle__switch::before {
		background: linear-gradient(to top, $cool-gray-100, $cool-gray-90);
	}

	.cds--tile-input:checked+.cds--tile>.cds--tile__checkmark svg {
		fill: black;
	}

	.ibp-welcome-banner-content {
		.ibp-welcome-tile-item {

			&:hover,
			&:focus {
				background: linear-gradient(to top, $cool-gray-100, $cool-gray-90);
			}
		}
	}

	.cds--dropdown__wrapper {
		.cds--list-box__menu-item--highlighted {
			.cds--list-box__menu-item__option {
				text-decoration: underline;
			}
		}
	}

	.ibp-form-slider {

		.cds--slider__thumb,
		.cds--slider__thumb:active,
		.cds--slider__filled-track {
			background-color: black;
		}
	}

	.ibm .ibp-archive-batch-action-bar .ibp-archive-bar-item .ibp-archive-bar-button {
		border: 1px solid black;

		&:focus {
			outline: 1px dotted black;
			outline-offset: -5px;
		}
	}
}

.cds--tooltip__label {
	align-items: baseline;
	color: $white-0;
}

.ibp--tooltip__label {
	align-items: baseline;
	color: $white-0;


	font-size: .75rem;
	font-weight: 400;
	line-height: 1.33333;
	letter-spacing: .32px;
	display: inline-flex;
}

// Component imports
@import './components/AddAnchorPeerModal/addAnchorPeerModal';
@import './components/AddIdentityModal/addIdentityModal';
@import './components/AddUserModal/addUserModal';
@import './components/AuthDetails/authDetails';
@import './components/AuthSetup/authSetup';
@import './components/AuthSetupAddUsers/authSetupAddUsers';
@import './components/AuthSetupAuthentication/authSetupAuthentication';
@import './components/AuthSetupSuccess/authSetupSuccess';
@import './components/BlockchainTooltip/blockchainTooltip';
@import './components/Breadcrumb/breadcrumb';
@import './components/CAAddUserModal/caAddUser';
@import './components/CADetails/caDetails';
@import './components/CAModal/caModal';
@import './components/CertificateAuthority/certificateAuthority';
@import './components/CertificateList/certificateList';
@import './components/Chaincodes/chaincodes';
@import './components/ChaincodeModal/chaincodeModal';
@import './components/ChaincodesPage/chaincodesPage';
@import './components/ChangePasswordModal/changePasswordModal';
@import './components/ChannelACLs/channelAcls';
@import './components/ChannelAnchorPeers/channelAnchorPeers';
@import './components/ChannelBlock/channelBlock';
@import './components/ChannelChaincode/channelChaincode';
@import './components/ChannelConsenterModal/channelConsenterModal';
@import './components/ChannelDetails/channelDetails';
@import './components/ChannelMembers/channelMembers';
@import './components/ChannelOrdererUrl/channelOrdererUrl';
@import './components/Channels/channels';
@import './components/ChannelModal/channelModal';
@import './components/ChannelModal/Wizard/Prerequisites/prerequisites';
@import './components/ChannelModal/Wizard/Review/review';
@import './components/ChannelModal/Wizard/BlockCuttingParams/blockCuttingParams';
@import './components/ComplexityScale/complexityScale';
@import './components/ConfigOverride/configOverride';
@import './components/DeleteCAUser/deleteCAUser';
@import './components/ConnectionProfileModal/connectionProfileModal';
@import './components/GettingStartedModal/gettingStartedModal';
@import './components/GettingStartedModal/ecosystemDiagram';
@import './components/Dropzone/dropzone';
@import './components/EditAuthSettingsModal/editAuthSettingsModal';
@import './components/EditAuthSchemePanel/editAuthSchemePanel';
@import './components/EmailChips/emailChips';
@import './components/ExportModal/exportModal';
@import './components/FileUploader/fileUploader';
@import './components/Form/form';
@import './components/GenerateCertificate/generateCertificate';
@import './components/GenerateMSPModal/generateMSPModal';
@import './components/GenericChips/genericChips';
@import './components/HiddenText/hiddenText';
@import './components/HSMConfig/hsmConfig';
@import './components/Identities/identities';
@import './components/IdentityExpiration/identityExpiration';
@import './components/IdentityModal/identityModal';
@import './components/ImportantBox/importantBox';
@import './components/ImportCAModal/importCAModal';
@import './components/ImportModal/importModal';
@import './components/ImportMspModal/importMspModal';
@import './components/ImportOrdererModal/importOrdererModal';
@import './components/ImportPeerModal/importPeerModal';
@import './components//InstallChaincodeModal/installChaincodeModal';
@import './components/InstallChaincodeModal/installChaincodeModal';
@import './components/InstantiateChaincodeModal/instantiateChaincodeModal';
@import './components/InstantiatedChaincodes/instantiatedChaincodes';
@import './components/ItemContainer/itemContainer';
@import './components/ItemContainerTile/itemContainerTile';
@import './components/ItemContainerTile/ItemTileLabels/itemTileLabels';
@import './components/ItemMenu/itemMenu';
@import './components/JoinChannelModal/joinChannelModal';
@import './components/JoinOSNChannelModal/joinOSNChannelModal';
@import './components/JsonInput/jsonInput';
@import './components/LeftNav/leftNav';
@import './components/LeftNavItem/leftNavItem';
@import './components/LoadingWithContent/loadingWithContent';
@import './components/LogSettings/logSettings';
@import './components/Login/login';
@import './components/Main/main';
@import './components/MigrationPage/migrationPage';
@import './components/AuditLogs/auditLogs';
@import './components/Access/access';
@import './components/MSPDefinitionModal/mSPDefinitionModal';
@import './components/MspDeleteModal/mspDeleteModal';
@import './components/MspDetailsModal/mspDetailsModal';
@import './components/Msps/msps';
@import './components/Mustgather/mustgather';
@import './components/NodeDetails/nodeDetails';
@import './components/Nodes/nodes';
@import './components/NotFound/notFound';
@import './components/Notifications/notifications';
@import './components/OrdererAdmins/ordererAdmins';
@import './components/OrdererConsenterModal/ordererConsenterModal';
@import './components/OrdererDetails/ordererDetails';
@import './components/OrdererMembers/ordererMembers';
@import './components/OrdererModal/ordererModal';
@import './components/Orderers/orderers';
@import './components/OrganizationDetails/organizationDetails';
@import './components/PageContainer/pageContainer';
@import './components/PageHeader/pageHeader';
@import './components/PeerChaincode/peerChaincode';
@import './components/PeerDetails/peerDetails';
@import './components/PeerModal/peerModal';
@import './components/Peers/peers';
@import './components/ProposeChaincodeModal/proposeChaincodeModal';
@import './components/ReallocateModal/reallocateModal';
@import './components/ReleaseNotes/releaseNotes';
@import './components/RequestAccess/requestAccess';
@import './components/ResetPasswordModal/resetPasswordModal';
@import './components/Settings/settings';
@import './components/SidePanel/sidePanel';
@import './components/SidePanelError/sidePanelError';
@import './components/SidePanelInlineConfirmation/sidePanelInlineConfirmation';
@import './components/SidePanelWarning/sidePanelWarning';
@import './components/SignatureAuditLogModal/signatureAuditLogModal';
@import './components/SignatureCollection/signatureCollection';
@import './components/SignatureDetailModal/signatureDetailModal';
@import './components/SignatureNotification/signatureNotification';
@import './components/Stepper/stepper';
@import './components/StickySection/stickySection';
@import './components/SubNavItem/subNavItem';
@import './components/Support/support';
@import './components/Timeline/timeline';
@import './components/TimelineCancelButton/timelineCancelButton';
@import './components/TitleBar/titleBar';
@import './components/TransactionModal/transactionModal';
@import './components/TranslateLink/translateLink';
@import './components/UpdateChannelMspModal/updateChannelMspModal';
@import './components/UsageForm/usageForm';
@import './components/UserDetailsModal/userDetailsModal';
@import './components/UserInfo/userInfo';
@import './components/UserProfile/userProfile';
@import './components/VisibilityToggle/visibilityToggle';
@import './components/WelcomeBanner/welcomeBanner';
@import './components/WelcomeBanner/welcomeBannerTile';
@import './components/WelcomeMessage/welcomeMessage';
@import './components/Wizard/wizard';
@import './components/DeleteAccessModal/deleteAccessModal';
