.ibp__channel--container {
	// margin-top: 2rem;

	.ibp-item-container {
		margin-top: 2rem;
	}
}

.ibp-node-tile-msp {
	@include type-style('label-01');
	@include singleLineEllipsis;
	color: $cool-gray-30;
	padding-top: 0.5rem;
}
