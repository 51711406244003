@keyframes welcomeBannerItemFadeIn {
	0% {
		opacity: 0;
		transform: translateY(-2rem);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes welcomeBannerItemFadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes bannerEnter {
	0% {
		transform: translateY(-21.625rem);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes bannerLeave {
	0% {
		opacity: 1;
		transform: translateY(0px);
	}

	100% {
		opacity: 0;
		transform: translateY(-21.625rem);
	}
}

.ibp-welcome-banner-container {
	overflow: auto;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 48rem;
	width: 100%;
	background-color: $itemBackground;
	padding-top: 3.75rem;
	z-index: $firstLayer;

	.ibp-welcome-banner-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 2.25rem 1rem 0 1rem;

		.ibp-welcome-banner-row {
			display: flex;
		}
	}

	.ibp-welcome-button-container {
		position: absolute;
		bottom: 0;
		width: 100%;

		.ibp-welcome-banner-close-button {
			height: 3.5rem;
			min-width: 17rem;
			background-color: $itemBackground;
			border: 0;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: auto;
			transition: $basic-duo-transition;

			&:hover {
				background-color: $cool-gray-80;
			}

			&:focus {
				box-shadow: inset 0px 0px 0px 2px $white;
				background-color: $cool-gray-80;
				outline: 0;
			}
		}
	}
}

.welcome--transitioning--in {
	animation: bannerEnter 350ms infinite;
	animation-fill-mode: forwards;
}

.welcome--transitioning--out {
	animation: bannerLeave 350ms infinite;
	animation-fill-mode: forwards;
}

.welcome-banner-overlay {
	opacity: 0;
	animation: welcomeBannerItemFadeIn 350ms 1;
	animation-fill-mode: both;
	position: fixed;
	top: 48rem;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 75%);
	transition: $basic-duo-transition;
}

.welcome-banner-overlay.welcome--banner-transitioning--out {
	// animation: welcomeBannerItemFadeOut 350ms 1;
	// animation-fill-mode: both;
	display: none;
}


// allow welcome banner to scroll when zoomed in to at least 200%
@media screen and (max-width: 948px) {
	.ibp-welcome-banner-container {
		.ibp-welcome-banner-content {
			height: 65rem;
			overflow: scroll;
		}
	}
}
