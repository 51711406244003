.ibp-flex {
	display: flex;
}
.ibp-row {
	display: flex;
	flex-direction: row;
}
.ibp-column {
	display: flex;
	flex-direction: column;
}
.width-10 {
	width: 10%;
}
.width-20 {
	width: 20%;
}
.width-25 {
	width: 25%;
}
.width-30 {
	width: 30%;
}
.width-40 {
	width: 40%;
}
.width-50 {
	width: 50%;
}
.width-60 {
	width: 60%;
}
.width-70 {
	width: 70%;
}
.width-75 {
	width: 75%;
}
.width-80 {
	width: 80%;
}
.width-90 {
	width: 90%;
}
.width-100 {
	width: 100%;
}

.p-lr-10 {
	padding-left: 10px;
	padding-right: 10px;
}
