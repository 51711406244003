.ibp-node-ca-tile-name {
	@include type-style('body-short-01');
	@include font-weight('regular');
	color: $cool-gray-30;
}

.ipb-node-ca-tile-cloud-type {
	@include font-weight('semibold');
	bottom: 1rem;
	color: $cool-gray-30;
	position: absolute;
	font-size: 0.875rem;
}
