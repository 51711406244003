.ibp-request-access-container {
	padding-left: 5rem;
	padding-top: 3rem;
}

.ibp-request-access-label {
	padding: 1.5rem 0 1rem 0;
}

.ibp-not-authorized-label {
	padding-top: 5rem;
}

.grey_txt{
	color: #ccc;
	font-size: 0.9rem;
}
