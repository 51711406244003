.ibp--dropzone-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.FileInput {
	display: none;
}
