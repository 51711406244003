.migrationPanel {
	.pageTitle {
		margin-bottom: 1rem;
	}

	.migrationWizardModal {
		margin: 0 0 1.5rem 1rem;
	}

	.twistyWrapper {
		padding: 1rem;
		margin-bottom: 1rem;
		font-size: 1.5rem;
		background: #21272a;
	}

	.twistyTitle {
		background: #0353e9;
		padding: 1rem;
		cursor: pointer;
	}

	.twistyContent {
		margin-top: 0.5rem;
		margin-left: 1rem;

		.componentListWrapper {
			width: 60%;
		}

		h2 {
			margin-bottom: 1rem;
			font-size: 1.15rem;
		}

		.progressInfoContent {
			display: flex;
			justify-content: flex-start;

			p {
				margin-left: 1rem;
				font-size: .7rem;
				margin-top: 0.25rem;
			}

			.progressIcon {
				margin-top: 0.2rem;
			}

			.skeletonText {
				width: 8rem;
				margin-top: 0.2rem;
			}

			.iconNotStarted {
				fill: #4589ff;
			}

			.iconSuccess {
				fill: #24a148;
			}

			.iconInProgress {
				fill: #ff832b;
			}
		}

		.componentListSkeleton {
			width: 20rem;
		}
	}

	.centerText {
		width: 100%;
		text-align: center;
		font-weight: 500;
	}

	.infoTitle {
		font-weight: 500;
		font-size: 1.15rem;
		margin-top: 1.5rem;
		border-bottom: 1px #555 solid;
		margin-bottom: 0.5rem;
		padding-bottom: 0.2rem;
	}

	.newUrl {
		margin: 1.6rem 0 0 1rem;
	}

	.cds--accordion__heading {
		background: #0353e9;
	}

	.cds--accordion__heading:hover,
	.cds--accordion__heading:hover::before {
		background: #0353e9;
		border: 1px #fff solid;
	}

	.cds--accordion__heading,
	.cds--accordion__heading::before {
		border: 1px #0353e9 solid;
	}

	.cds--accordion__content {
		padding-right: 1rem;
	}

	.toggleHeader {
		margin-bottom: 1rem;
	}

	p {
		margin-left: 1rem;
		font-size: 0.9rem;
	}

	.cds--structured-list-td {
		font-size: .7rem;
		line-height: 0.8rem;
	}

	.tinyText {
		font-size: 0.8rem;
		color: #f44a4a;
	}

	.migration-progress-spinner {
		display: inline-block;
		margin-left: 1rem;
		opacity: 0.5;
		margin-right: 0;
		padding: 0;

		svg {
			fill: none !important;
		}
	}

	.mig-button {
		padding-right: 2rem;
	}

	.progressWrap {
		text-align: center;
		width: 100%;
		margin-top: 7rem;
		min-width: 38rem;
	}

	.stepBubble {
		background-color: #0353e9;
		color: #fff;
		border-radius: 25%;
		border: 3px #0353e9 solid;
		width: 3rem;
		display: inline-block;
		height: 3rem;
		line-height: 2.7rem;
		text-align: center;
		position: relative;
		font-weight: 500;
	}

	.stepBubble.step-inactive {
		background-color: #bbb;
		border-color: #4d5358;
		color: #4d5358;
	}

	.stepBubble.step-active {
		background-color: #121619;
		color: #fff;
	}

	.stepBubble.step-error {
		background-color: #f44a4a;
		border-color: #f44a4a;
		color: #fff;
	}

	.stepLine {
		border-top: 3px #0353e9 solid;
		width: 10%;
		height: 0.5rem;
		display: inline-block;
		min-width: 5.2rem;
	}

	.stepLine.step-inactive {
		border-color: #3a3d3f;
	}

	.stepLine.step-error {
		border-color: #f44a4a;
	}

	.stepLineWrap {
		position: relative;
	}

	.stepLineMotion {
		position: absolute;
		border-top: 3px #3d7cf0 solid;
		width: 0.5rem;
		left: 0rem;
		top: 0.4rem;
		padding: 0;
		margin: 0;
		min-width: 1px;

		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-name: stepLineMotion;
		transition-timing-function: linear;
	}

	.stepIndicator {
		position: absolute;
		top: 2.5rem;
		left: 1.1rem;
		display: none;
		color: #fff;
		font-size: 1rem;

		-webkit-animation-duration: 2s;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	.stepDescription {
		position: absolute;
		top: -2rem;
		left: 0rem;
		color: #fff;
		font-size: .8rem;
		line-height: 1rem;
		width: 9rem;
		text-align: center;
		margin-left: -3rem;
		font-weight: 400;
	}

	.statusSummary {
		color: #fff;
		margin-top: 1.5rem;
		font-weight: 500;
		font-size: 1.2rem;
	}

	.errorTxt {
		width: 55%;
		text-align: left;
		margin: auto;
		margin-top: 1rem;
		color: #fff;
		background: #f44a4a;
		padding: 0.5rem 1rem;
	}

	.bounce {
		animation-name: bounce;
		animation-timing-function: cubic-bezier(0.27, 0.84, 0.42, 1.00);
		display: block;
	}

	@keyframes bounce {
		0% {
			transform: scale(1, 1) translateY(0);
		}

		10% {
			transform: scale(1.1, .9) translateY(0);
		}

		30% {
			transform: scale(.8, 1.1) translateY(-6px);
		}

		50% {
			transform: scale(1.05, .95) translateY(0);
		}

		100% {
			transform: scale(1, 1) translateY(0);
		}
	}

	@keyframes stepLineMotion {
		0% {
			transform: translate(0rem, 0rem);
		}

		100% {
			transform: translate(8rem, 0rem);
		}
	}

	.newConsoleWrap {
		background: #3b3d3e;
		padding: 0.6rem 1.5rem;
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	.leftParagraphMore {
		margin-left: 3rem;
		line-height: 1.2rem;
	}
}

.errorTxt,
.invalidVersionText {
	color: #f44a4a;
}

.checking_text {
	color: #81a4ec;
}

.checking_text2 {
	margin-bottom: 2rem;
}

.mig_version_wrap {
	margin-top: 1rem;

	.mig_version_label,
	.mig_version_value,
	.mig_min_version_value,
	.mig_valid_txt {
		font-size: 0.8rem;
		display: inline-block;
	}

	.mig_version_label {
		font-size: 0.8rem;
		min-width: 40%;
	}

	.mig_min_version_value {
		margin-left: 0.5rem;
	}

	.mig_valid_txt {
		background: #81a4ec;
		margin-left: .5rem;
		padding: 0.2rem 0.5rem;
		border-radius: 1rem;
	}

	.versionRowWrap {
		padding-bottom: 0.3rem;
	}
}

.leftParagraph {
	margin-left: 1rem;
	line-height: 1.2rem;
}

.tinyTextWhite {
	font-size: 0.8rem;
}
