.ibp-msp-joined-peers {
	font-size: 1.75rem;
	// margin-top: 1.5rem;
}

.ibp-nodes-empty-state {
	margin-top: 1.5rem;
}

.ibp-open-connection-profile {
	min-width: 100%;
}
