.ibp-container-tile {
	background-color: $itemBackground;
	border-bottom: 1px solid $background;
	border-right: 1px solid $background;
	color: $text;
	// margin-right: 0.0625rem;
	// margin-bottom: 0.0625rem;
	padding: 1rem;
	position: relative;
	margin: 0.0625rem;
	margin: 0.0625rem;
    box-shadow: 0px 0px 4px 0px #{$cool-gray-80}50;

	&.ibp-container-tile-pending {
		background-color: #{$cool-gray-90}50;

		&:hover,
		&:focus {
			background-color: #{$cool-gray-80}50;
		}
	}

	&.ibp-container-tile-link {
		cursor: pointer;

		&:focus {
			box-shadow: inset 0px 0px 0px 2px white;
			outline: 0;
			transition: $basic-duo-transition;
		}
	}

	.cds--overflow-menu__wrapper {
		position: absolute;
		right: 0;
		top: 0.5rem;

		.cds--btn--icon-only {
			background-color: transparent;
			padding: 0;
			height: 24px;
			width: 24px;
		}
	}

	// .cds--overflow-menu {
	// 	height: 0;
	// 	overflow: hidden;
	// 	position: absolute;
	// 	right: 0;
	// 	top: 0.5rem;
	// }

	.ibp-item-menu-loading {
		position: absolute;
		right: 0;
		top: 0;
	}

	.ibp-new-item {
		background-color: $navigationHover;
		position: absolute;
		width: 100%;
		height: 0;
		top: 0px;
		left: 0px;
	}

	&.ibp-container-tile-selected {
		cursor: pointer;
		box-shadow: inset 0px 0px 0px 1px $action;

		&:focus {
			box-shadow: inset 0px 0px 0px 2px white;
			outline: 0;
			transition: $basic-duo-transition;
		}
	}

	&.ibp-container-tile-placeholder {
		background: linear-gradient(to bottom, $cool-gray-90 0%, $cool-gray-100 95%);
		outline: 0;
	}
}

.ibp-container-tile:hover,
.ibp-container-tile:focus,
.ibp-container-tile:focus-within {
	background-color: $itemHover;
	transition: $basic-duo-transition;

	.cds--overflow-menu {
		height: auto;
		overflow: visible;
	}
}

.ibp-container-grid-wider-tiles {

	.ibp-container-tile:hover,
	.ibp-container-tile:focus,
	.ibp-container-tile:focus-within {
		background-color: $itemHover;
		transition: $basic-duo-transition;

		.cds--overflow-menu {
			height: auto;
			overflow: visible;
		}
	}

	.ibp-container-grid-add:hover,
	.ibp-container-grid-add:focus,
	.ibp-container-grid-add:focus-within {
		background-color: $actionHover;
		transition: $basic-duo-transition;
	}
}

.ibp-container-tile .ibp-container-tile-title h4 {
	margin-bottom: 0;
}

@keyframes skeletonShimmer {
	0% {
		opacity: 0.25;
	}

	100% {
		opacity: 0.85;
	}
}

.ibp-container-tile-skeleton {
	pointer-events: none;

	.ibp-container-tile-skeleton-title {
		animation: skeletonShimmer 600ms linear infinite forwards alternate;
		background-color: $itemHover;
		display: inline-block;
		height: 1.25rem;
		width: 6rem;
	}

	.ibp-container-tile-skeleton-subtitle {
		animation: skeletonShimmer 600ms linear infinite forwards alternate;
		background-color: $itemHover;
		display: inline-block;
		height: 1rem;
		width: 3rem;
	}
}
