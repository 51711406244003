@use '@carbon/styles/scss/components/button';

$background: $cool-gray-100;
$text: $white-0;
$inverseText: $cool-gray-90;
$inverseBackground: $text;
$inverseText: $background;
$itemBackground: $cool-gray-90;
$itemHover: $cool-gray-80;
$action: button.$button-primary;
// #0f62fe;
// $interactive-01;
$actionHover: $blue-70;
$secondaryAction: $blue-70;
$secondaryActionHover: $blue-80;
$navigationHover: $blue-40;
$cancelButton: $cool-gray-60;
$cancelButtonHover: $cool-gray-70;
$buttonDisabled: $cool-gray-80;
$sidePanelBackground: $cool-gray-100;
$success: $green-50;
$white: $white-0;

// alert colors
$running: $green-50;
$pending: #f1c21b; //$support-03;
$warning: #f1c21b; //$support-03;
$warningSecondary: #735f1b;
$stopped: #da1e28; //$support-01;
$stoppedSecondary: $red-80;

// fields
$field-background: $cool-gray-90;
$field-color: $cool-gray-50;
$field-border: transparent;
$field-label-color: $cool-gray-30;
$field-focus-color: $cool-gray-10;
$field-focus-border: $white-0;
$field-error: #da1e28; //$support-01;

// color coding
$codeKeywordPrimary: $purple-40;
$codeKeywordSecondary: $teal-40;

// transition
$basic-duo-transition: all 0.2s cubic-bezier(0.2, 0.2, 0.38, 0.9);

// z index variables
$pageContentLayer: 0;
$firstLayer: 1000;
$secondLayer: 2000;
$thirdLayer: 3000;
$fourthLayer: 4000;
$fifthLayer: 5000;
$panelOpenLayer: 6000;
$panelOpenButtonLayer: 7000;
$sidePanelError: 8000;
$highestLayer: 10001;

$inverse-support-01: $red-60;
$hover-danger: button.$button-danger-hover;
$disabled-03: $cool-gray-60;
$support-01: $red-60;
$visited-link: $link-visited;
