.ibm {
	.ibp-loading-with-content {
		.cds--loading {
			.cds--loading__svg {
				.cds--loading__stroke {
					stroke: $blue-60;
				}
			}
		}
	}
}

.ibp-loading-with-content.cds--loading-overlay {
	background: $cool-gray-100 100%;
}
