.ibp-modal-title {
	margin-bottom: 2rem;
}

.ibp-modal-desc {
	margin-bottom: 2rem;
}

.ibp-add-user-loading {
	position: relative;
}

.ibp-add-users-root-check {
	display: flex;
	flex-direction: row;
	width: 14rem;
	align-items: center;
}

hr {
	border: 0;
	border-top: 1px solid $cool-gray-70;
	display: block;
	height: 1px;
	margin: 1em 0;
	padding: 0;
}
