.ibp-notifications {
	position: fixed;
	top: 3rem;
	right: 0px;
	z-index: $firstLayer;

	.cds--toast-notification__details {
		position: relative;
	}

	.cds--toast-notification--info {
		background-color: $white;
		border-left: 0.1875rem solid $action;
	}

	.cds--toast-notification--success {
		background-color: $white;
		border-left: 0.1875rem solid $success;
	}

	.cds--toast-notification__close-button {
		background-color: transparent;
	}

	.cds--toast-notification__caption,
	.cds--toast-notification__subtitle {
		color: $inverseText;
	}

	.cds--toast-notification__close-button {
		.cds--toast-notification__icon {
			fill: $background;
		}
	}

	.ibp-notification-icon {
		position: absolute;
		top: 0rem;
		left: -0.5rem;
	}

	.ibp-notification-icon-spin {
		position: absolute;
		bottom: 0.25rem;
		right: -0.75rem;
		width: auto;

		.cds--loading--small {
			.cds--loading__svg {
				stroke: #0062ff;
			}
		}
	}

	.cds--toast-notification--error {
		.cds--toast-notification__icon {
			fill: $red-60;
		}
	}

	.cds--toast-notification--warning {
		.cds--toast-notification__icon {
			fill: $warning;
		}
	}

	.cds--toast-notification--success {
		.cds--toast-notification__icon {
			fill: $green-50;
		}
	}

	.ibp-info-notification {
		padding-bottom: 1rem;
	}

	.ibp-notification-icon-spin-relative {
		.cds--loading--small {
			.cds--loading__svg {
				stroke: #0062ff;
			}
		}
	}
}

.cds--toast-notification__subtitle {
	padding-top: 0.25rem;
}
