.ibp-edit-auth-settings {
	.tinyText {
		font-size: 0.9rem;
	}
}

.allow-default-wrap {
	margin-left: 7rem;
	display: inline-block !important;
}

.allow-default-label{
	display: inline-block;
}
