.ibp-profile-button {
	background: transparent;
	border: 0;
	cursor: pointer;
	text-align: left;
	transition: $basic-duo-transition;
	width: 100%;

	&:hover {
		background: $itemHover;
	}
}

.ibp-logout-successful {
	.cds--modal-header {
		button {
			display: none;
		}
	}

	a:visited {
		color: $visited-link;
	}
}
