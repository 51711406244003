.ibp-usage-title {
	@include font-weight('semibold');
	display: inline;

	.ibp--tooltip__label {
		@include type-style('productive-heading-03');
	}
}

.ibp-usage-form .ibp-tooltip-container {
	display: inline-block;
}
