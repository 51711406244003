.ibp-complexity-scale-outer-container {
	align-items: center;
	display: flex;
	color: $cool-gray-30;

	.ibp-complexity-level-label {
		font-size: 0.75rem;
		padding-right: 0.5rem;
	}

	.ibp-complexity-scale-container {
		display: flex;

		.ibp-complexity-scale-item {
			background-color: $cool-gray-60;
			margin-right: 0.25rem;
			height: 0.125rem;
			width: 1.5rem;
		}
	}
}
