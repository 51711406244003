.node-details-panel {
	background: #202529;
	margin: 2rem 0;
	padding: 1.5rem;

	.node-details-title {
		position: relative;
	}

	.node-details-data {
		@include type-style('label-01');
		color: $cool-gray-30;
		display: grid;
		grid-column-gap: 1rem;
		grid-template-columns: 1fr 1fr;
		margin-top: 1rem;

		.node-details-column {
			display: grid;
			grid-column-gap: 1rem;
			grid-row-gap: 0.5rem;
			grid-template-columns: auto 1fr;

			.node-details-field-name {
				white-space: nowrap;
			}

			.node-details-field-value {
				word-break: break-all;
				color: $white;
			}
		}
	}
}
