.ibp-actions {
	margin-bottom: 2rem;
}

.ibp-action-tiles {
	display: grid;
	grid-auto-rows: 1fr;
	grid-gap: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
	margin-bottom: 3rem;
	max-width: 928px;

	&:before {
		content: '';
		grid-column: 1 / 1;
		grid-row: 1 / 1;
		padding-bottom: 60%;
		width: 0;
	}

	&:first-child {
		grid-column: 1 / 1;
		grid-row: 1 / 1;
	}

	.ibp-action-tile {
		background-color: $blue-60;
		overflow: hidden;
		padding: 1rem;
		position: relative;
		width: 100%;

		&:hover {
			background: $actionHover;
		}
	}
}

.ibp-action-tiles> :first-child {
	grid-column: 1/1;
	grid-row: 1/1;
}

.ibp-node-msp-tile-name-sub {
	@include font-weight('regular');
	color: $cool-gray-30;
}

.ibp-msp-edit,
.ibp-msp-download,
.ibp-msp-delete {
	align-items: center;
	background-color: transparent;
	border: none;
	bottom: 0.75rem;
	display: flex;
	height: 1.875rem;
	justify-content: center;
	padding: 0.25rem;
	position: absolute;
	width: 1.875rem;

	&:hover,
	&:focus {
		background-color: $cool-gray-70;
		cursor: pointer;
	}

	svg {
		height: 1.25rem;
		width: 1.25rem;
	}
}

.ibp-msp-delete {
	right: 1rem;
}

.ibp-msp-download {
	right: 3rem;
}

.ibp-msp-edit {
	right: 5rem;
}

.ibp-definition-icons {
	align-items: center;
	bottom: 0.75rem;
	display: flex;
	height: 1.875rem;
	justify-content: center;
	padding: 0.25rem;
	position: absolute;
	right: 1rem;
	width: 1.875rem;
}

.ibp-actions-tooltip {
	margin-left: 0.75rem;
}

.ibp__button--container {

	.cds--btn--secondary+.cds--btn--primary,
	.cds--btn--tertiary+.cds--btn--danger--primary {
		margin-left: 0;
	}
}
