.ibm {
	.ibp-identity-expiration {
		@include type-style('label-01');
		color: $warning;
		padding-left: 1.25rem;
		position: relative;

		svg {
			position: absolute;
			bottom: 0px;
			left: 0px;
			fill: $warning;
			margin-right: 0.5rem;
		}
	}

	.ibp-identity-admin {
		@include type-style('label-01');
	}
}
