.ibp-modal-title {
	margin-bottom: 2rem;
}

.ibp-modal-desc {
	margin-bottom: 2rem;
}

.cds--btn--secondary {
	background-color: $cancelButton;
	color: $text;
	min-width: 100px;
	margin-bottom: 1rem;
}

.cds--btn--secondary:hover {
	background-color: $cancelButtonHover;
}

// .cds--btn--primary {
// 	background-color: $action;
// 	min-width: 100px;
// }

// .cds--btn--primary:hover {
// 	background-color: $actionHover;
// }

.cds--file-filename.ibp-importCA--fileName {
	margin-bottom: 0.5rem;
}

.ibp-panel--content {
	.cds--file__state-container {
		display: none;
	}

	.ibp-clear-files-button {
		margin-top: 1.5rem;
	}
}

.hidden_section {
	height: 0px;
	overflow: hidden;
}

.ibp-link {
	cursor: pointer;
}

fieldset.ibp-ca-locations {
	legend {
		font-weight: 400;
		margin-bottom: 1rem;
	}

	.ibp-ca-location {
		height: 5rem;
		width: 100%;
	}
}

.ibp-ca-locations {
	margin-top: 1rem;
	margin-bottom: 1rem;

	.ibp-ca-location {
		background-color: transparent;
		border: 1px solid $itemBackground;
		width: 100%;
		height: 5rem;
		padding: 1rem;
		position: relative;

		&:hover,
		&:focus {
			background-color: $itemHover;
		}

		&.cds--tile--disabled{
			background: transparent;
			color: #555;
		}

		.ibp-import-node-add-icon {
			bottom: 1rem;
			position: absolute;
			right: 1rem;
		}

		.cds--tile__checkmark {
			display: none;
		}
	}

	.cds--tile--is-selected {
		outline: 1px solid $action;
	}
}

.summary-section {
	margin-bottom: 1rem;

	.summary-label {
		@include type-style('label-01');
		color: $cool-gray-30;
	}

	.summary-value {
		color: $text;
		word-wrap: break-word;
	}
}

.ibp-usage-footer {
	@include type-style('body-short-01');
	background-color: $inverseBackground;
	border-top: 3px solid $blue-50;
	color: $inverseText;
	display: flex;
	flex-direction: row;

	.ibp-usage-footer-column {
		flex-grow: 1;
		flex-shrink: 1;
		padding: 1rem;

		.ibp-usage-footer-heading {
			@include font-weight('semibold');
		}

		p {
			@include type-style('body-short-01');
		}
	}
}

.ibp-ca-database-wizard,
.ibp-ca-zone-wizard {
	.cds--radio-button-wrapper .cds--radio-button__label {
		align-items: left;
		justify-content: left;
		margin-top: 0.25rem;
	}
}

.ibp-db-tls-enabled {
	.cds--checkbox-wrapper {
		display: inline-block;
	}

	.ibp-tooltip-container {
		display: inline;
	}
}

.ibp-wizard-step {
	.db-toggle-label-div {
		display: flex;
		flex-direction: row;
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;

		>* {
			flex-grow: 0;
		}

		.db-toggle-label {
			flex-grow: 1;
		}

		.cds--toggle__label {
			margin-top: -0.5rem;
		}

		.cds--toggle--small+.cds--toggle__label .cds--toggle__appearance:before {
			height: 1.5rem;
			width: 2.625rem;
		}

		.cds--toggle--small+.cds--toggle__label .cds--toggle__appearance:after {
			height: 1.125rem;
			width: 1.125rem;
		}
	}
}

.ibp-replica-sets .cds--slider-text-input {
	margin-right: 4rem;
	width: 2rem;
}

.intermediate-ca-desc,
.ibp-ca-database-desc {
	margin-bottom: 1rem;
}

.ibp-advanced-ca-checkboxes-label {
	margin-bottom: 0.5rem;
}

.ibp-advanced-ca-checkboxes {
	display: flex;
	flex-direction: row;
	width: 1rem;
	white-space: nowrap;
}

.ibp-ca-hsm-desc {
	margin-bottom: 1rem;
}

.ibp-ca-zone-desc {
	margin-bottom: 1rem;

	.ibp-ca-zone-link,
	.ibp-ca-zone-link:hover {
		display: block;
		margin-bottom: 1rem;
		margin-top: 1rem;
		width: max-content;
	}
}
