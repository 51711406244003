.ibp-transaction-data {
	margin-top: 1rem;
	width: 100%;
	background-color: $cool-gray-90;
	border: solid 2px $field-border;
	color: $cool-gray-10;
	line-height: 20px;
	padding: 0.5rem;
	overflow: auto;
}

.ibp-transaction-data div {
	@include font-weight('regular');
	// @include font-face-mono();
	@include type-style('code-02');
	word-break: break-all;
}

.ibp-transaction-label {
	@include font-weight('semibold');
	margin-bottom: 0.5rem;
}

.ibp-transaction-operation {
	margin-right: 0.5rem;
	color: $codeKeywordPrimary;
	@include font-weight('semibold');
}

.ibp-transaction-section {
	margin-bottom: 2rem;
}

.ibp-transaction-text {
	@include font-weight('semibold');
	color: $field-color;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.ibp-transaction-title h1 {
	word-break: break-all;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.ibp-transaction-value {
	margin-left: 0.5rem;
}

.ibp-transaction-variable {
	margin-right: 0.5rem;
	color: $codeKeywordSecondary;
}
