.ibp-orderer-details-header {
	align-items: center;
	display: flex;
	margin-bottom: 2rem;

	.ibp-page-header,
	.ibp-orderer-details-location,
	.ibp-node-upgrade {
		border-right: 1px solid $cool-gray-50;
		margin-right: 1.5rem;
		padding-right: 1.5rem;
		padding-bottom: 0;
		padding-top: 0;
		min-height: 60px;
	}

	.ibp-orderer-settings-icon {
		cursor: pointer;

		.settingsIcon {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	.ibp-orderer-details-location {
		align-items: center;
		color: $field-color;
		display: flex;

		* {
			color: $cool-gray-50;
		}
	}

	.ibp-node-upgrade {
		align-items: left;
		display: flex;
		flex-direction: column;
	}
}

.ibp-orderer-details-cert-expiry-link {
	margin-top: 1rem;
}

.ibp-orderer-warning,
.ibp-not-available {
	margin-bottom: 1rem;
}

.ibp-usage-div {
	margin-top: 1rem;

	.ibp-item-container {
		padding-top: 2.25rem;
	}

	.ibp__table--toolbar {
		display: none;
	}
}

.orderer-details-nodes-container {
	padding-top: 2rem;
}

.connect-node-button-div {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.ibp-ordering-node-empty {
	text-align: center;

	.ibp-ordering-node-empty-image {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

.ibp-requires-attention {
	width: 100%;

	p {
		font-style: italic;
		font-size: 0.875rem;
	}

	.ibp-notification-icon {
		position: absolute;
		top: 0;
		right: 2rem;
	}
}

.ibp-requires-attention-small-image {
	flex-grow: 0;
	width: 8rem;
}

.running-partial-notice,
.pending-node-notice,
.missing-endorsement-policy {
	background-color: $field-background;
	padding: 2rem;
	display: flex;
	flex-direction: row;

	h3 {
		margin-bottom: 1rem;
	}

	p {
		button {
			z-index: $firstLayer;
		}
	}

	.go-to-node-button {
		margin-top: 2rem;
		width: 12rem;
		padding-right: 1rem;
	}

	.ibp-requires-attention-image {
		flex-grow: 0;
		margin-left: 2rem;
	}
}

.missing-endorsement-policy {
	margin-top: 1rem;
}

.ibp-pending-node-drill-down {
	padding: 2rem;
	display: flex;
	flex-direction: row;

	h3 {
		margin-bottom: 1rem;
	}

	.connect-node-button {
		padding-right: 1rem;

		svg {
			margin-left: 1rem;
		}
	}

	.ibp-requires-attention-image {
		flex-grow: 0;
		margin-left: 2rem;
	}
}

.ibp-orderer-details {
	.ibp--node-details-sticky-container-content {
		margin-top: 0;
	}

	.ibp-node-version-value {
		margin-top: 1rem;
		@include type-style('body-short-01');
		color: $cool-gray-30;
	}

	.ibp-upgrade-available {
		position: relative;
		padding-bottom: 5rem;
		background: linear-gradient(to right, #0000ff, #13171a);

		.ibp-new-version-actions {
			margin-top: 1rem;
		}

		button {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}

	.ibp-usage-div {
		.ibp-item-container {
			padding-top: 0;
		}
	}

	.ibp-node-version-and-hsm {
		display: flex;
		flex-direction: row;

		.ibp-hsm-tab {
			flex-direction: column;
			padding-bottom: 5rem;
			position: relative;

			button {
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		.ibp-hsm-tab:not(:first-child) {
			margin-left: 2rem;
			min-width: 60%;
		}
	}
}

.ibp-orderer-no-identity {
	margin-top: 1rem;

	button {
		margin-top: 1rem;
	}
}

.ibp-orderer-channel-info,
.ibp-orderer-channel-unjoin,
.ibp-orderer-channel-join {
	align-items: center;
	background: transparent;
	border: 0;
	bottom: 0.75rem;
	cursor: pointer;
	display: flex;
	height: 1.875rem;
	justify-content: center;
	margin: 0;
	outline: 0;
	padding: 0.25rem;
	position: absolute;
	width: 1.875rem;

	&:hover {
		background-color: $cool-gray-70;
	}

	&:focus {
		box-shadow: inset 0 0 0 2px $white-0;
	}

	svg {
		height: 1.25rem;
		width: 1.25rem;
	}
}

.ibp-orderer-channel-unjoin {
	right: 1rem;
}

.ibp-orderer-channel-join {
	right: 5rem;
}

.ibp-orderer-channel-info {
	right: 3rem;
}

.ibp-remove-orderer-confirm {
	margin-bottom: 1rem;
}

.ibp-orderer-channel-sub {
	@include font-weight('regular');
	color: $cool-gray-30;
}
