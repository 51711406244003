.ibp-auth-settings-email-title {
	@include type-style('label-01');
	color: $cool-gray-30;
	margin-top: 2rem;
}

.ibp-auth-settings-roles-title {
	@include type-style('body-short-01');
	color: $cool-gray-10;
	margin-top: 2rem;
}

.ibp-auth-settings-roles-desc {
	@include type-style('body-short-01');
	color: $cool-gray-30;
	margin: 0.5rem 0 0 2rem;
}

.ibp-auth-settings-role {
	padding-top: 1rem;
}

.cds--checkbox-label.ibp-auth-settings-role-label {
	padding-left: 2rem;
}

.ibp-user-email {
	margin-top: 2rem;
}
