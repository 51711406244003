.ibp-access-auth-services-label {
	padding-bottom: 1rem;
	display: flex;

	.ibp--tooltip__label {
		@include type-style('productive-heading-04');
	}
}

.ibp-access-auth-services-container {
	background: $cool-gray-90;
	height: 7.625rem;
	padding-left: 1.5rem;
	padding-top: 1rem;
	width: 23rem;
}

.ibp-access-iam-info-container {
	background: $cool-gray-90;
	height: 12rem;
	margin-top: 1rem;
	padding-left: 1.5rem;
	position: relative;
	width: 35rem;
}

.ibp-access-appId-lock-img {
	height: 1.875rem;
	margin-top: 1.5rem;
	width: 1.375rem;
}

.ibp-access-app-id-label {
	@include type-style('body-short-02');
	@include font-weight('semibold');
	padding-top: 0.5rem;
}

.ibp-access-cloud-service-label {
	@include type-style('body-short-01');
	color: $cool-gray-40 !important;
	margin-top: 0.25rem;

	.cds--tooltip-trigger__wrapper {
		height: 16px;
		button {
			padding: 0;
		}
	}
}

.ibp-access-configure-label.cds--link {
	@include type-style('body-short-01');
	cursor: pointer;
	text-decoration: underline;
}

.ibp-auth-access-modal-title {
	@include type-style('productive-heading-05');
	@include font-weight('light');
	color: $text;
}

.ibp-auth-access-modal-desc {
	margin-top: 1.5rem;
}

.ibp-access-iam-title {
	@include type-style('productive-heading-03');
	color: $cool-gray-10;
	padding-top: 1rem;
}

.ibp-access-iam-desc {
	@include type-style('body-short-02');
	color: $white-0;
	padding-top: 1rem;
}

.ibp-access-iam-link {
	padding-top: 1.5rem;
}

.ibp-iam-learn-more {
	@include type-style('body-short-01');
	color: $blue-40;
	display: block;
	margin-top: 1.5rem;
	width: max-content;
}

.ibp-nodes-iam-invite-users {
	@include type-style('body-short-01');
	background: $action;
	border: 2px solid transparent;
	bottom: 0;
	color: $white-0;
	cursor: pointer;
	display: inline-block;
	margin-bottom: 0.5px;
	margin-left: 0.125rem;
	margin-right: 0.125rem;
	overflow: hidden;
	padding: 1rem;
	position: absolute;
	right: 0;
	text-align: left;
	text-decoration: none;
	transition: $basic-duo-transition;

	&:hover {
		background-color: $actionHover;
	}

	&:focus {
		border: 2px solid $white;
		box-shadow: inset 0px 0px 0px 1px $cool-gray-100;
		outline: 0;
	}

	.ibp-iam-launch-icon {
		height: 1rem;
		margin-left: 1rem;
		width: 1rem;
	}
}

#authenticated_members {
	.ibp-deleting-spinner {
		margin-top: -0.5rem;
		float: right;
	}
}

.ibp-access-member-role-icon {
	margin-left: 1.5rem;
}

.ibp-access-member-new-icon {
	background-color: #81a4ec;
	color: #fff;
	border-radius: 1rem;
	padding: 0;
	text-align: center;
}

.ibp-access-member-you-icon {
	background-color: #0353e9;
	color: #fff;
	border-radius: 1rem;
	padding: 0;
	text-align: center;
}

.ibp-access-row {
	margin-bottom: 2rem;
}

.ibp-access-gear-icon {
	height: 2rem;
	padding: 0.5rem;
	margin-top: -.7rem;
	margin-right: -.3rem;
}

.ibp-access-gear-icon:hover {
	background: #121619;
	fill: #ccc;
}

.ibp-access-button {
	align-items: center;
	background: transparent;
	border: 0;
	bottom: .75rem;
	cursor: pointer;
	height: 1.875rem;
	justify-content: center;
	margin: 0;
	outline: 0;
	padding: .25rem;
	float: right;
	margin-top: -1rem;
}

.access-setting-value,
.access-setting-label {
	display: inline-block;
	font-size: 1rem;
}

.access-setting-value {
	margin-left: 4rem;
}

.access-setting-label {
	margin-bottom: 1rem;
}


.tinyTextWhite {
	font-size: 0.8rem;
}

.access-form-apikey-secret {
	width: 30rem;
}
