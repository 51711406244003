.ibp-orderer-member-container {
	padding: 2rem 0;

	.ibp-node-msp-tile-name-sub {
		@include font-weight('regular');
		color: $cool-gray-30;
	}

	.ibp-orderer-member-ou {
		color: $cool-gray-40;
		bottom: 1rem;
		margin-right: 1rem;
		position: absolute;
	}

	.ibp-orderer-member-download,
	.ibp-orderer-member-delete {
		align-items: center;
		background: transparent;
		border: 0;
		bottom: 0.75rem;
		cursor: pointer;
		display: flex;
		height: 1.875rem;
		justify-content: center;
		margin: 0;
		outline: 0;
		padding: 0.25rem;
		position: absolute;
		width: 1.875rem;

		&:hover {
			background-color: $cool-gray-70;
		}

		&:focus {
			box-shadow: inset 0 0 0 2px $white-0;
		}

		svg {
			height: 1.25rem;
			width: 1.25rem;
		}
	}

	.ibp-orderer-member-delete {
		right: 1rem;
	}

	.ibp-orderer-member-download {
		right: 3rem;
	}

	.ibp-orderer-member-singe.ibp-orderer-member-download {
		right: 1rem;
	}
}
