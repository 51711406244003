.ibp__auth {
	padding: 2rem 0 0 7rem;
	max-width: 48rem;
}

.ibp__auth-welcome-message {
	@include type-style('productive-heading-04');
	@include font-weight('light');
	color: $text;
	margin-top: 3rem;
}

.ibp__auth-outline {
	@include type-style('body-short-02');
	color: $cool-gray-30;
	padding: 0.75rem 0 2rem 0;
}

.ibp__auth-title {
	@include type-style('productive-heading-04');
	@include font-weight('light');
	color: $text;
	padding-top: 1.5rem;
}

.ibp__auth-desc {
	@include type-style('body-short-02');
	color: $text;
	padding-top: 0.75rem;
}

.ibp__auth-configuration--input-field {
	@include type-style('body-short-01');
	background: $cool-gray-80;
	border: 1px solid $cool-gray-50;
	color: $cool-gray-50;
	height: 2rem;
	margin: 0.5rem 0rem 1rem 0rem;
	padding: 0rem 1rem;
	width: 33rem;
}

.ibp__auth-configuration--buttons-container {
	margin-top: 2.5rem;
	width: 33rem;
}

.ibp__auth-configuration--buttons {
	@include type-style('body-short-01');
	border: 0;
	color: $text;
	cursor: pointer;
	height: 4rem;
	padding: 1.2rem 0rem 1.75rem 2rem;
	position: relative;
	text-align: left;
	width: 50%;
}

.ibp__auth-configuration--back-button {
	background-color: $cancelButton;
}

.ibp__auth-configuration--back-button:hover,
.ibp__auth-configuration--back-button:focus {
	background-color: $cancelButtonHover;
}

.ibp__auth-configuration--continue-button {
	background-color: $action;
}

.ibp__auth-configuration--continue-button:hover,
.ibp__auth-configuration--continue-button:focus {
	background-color: $actionHover;
}

.ibp__auth-configuration--back-button:disabled,
.ibp__auth-configuration--back-button[disabled],
.ibp__auth-configuration--continue-button:disabled,
.ibp__auth-configuration--continue-button[disabled] {
	background-color: $itemHover;
	cursor: not-allowed;
}

.ibp-help-icon {
	display: inline-flex;
	margin-left: 0.5rem;
	vertical-align: top;

	.cds--tooltip__trigger path,
	.cds--tooltip__trigger:hover path {
		fill: $text;
	}
}

.ibp-error-panel {
	background-color: $sidePanelBackground;
	margin-top: 1.5rem;
	// width: 33rem;
}
