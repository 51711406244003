.ibp-item-location-details {
	bottom: 1rem;
	margin-right: 1rem;
	position: absolute;

	.ibp-item-tile-details-label {
		@include type-style('label-01');
		align-items: center;
		color: $cool-gray-30;
		display: flex;
		padding-bottom: 0.5rem;

		&:last-of-type {
			padding-bottom: 0;
		}

		.ibp--item-location-icon {
			fill: $cool-gray-30;

			&.ibp-item-location-icon-patch-available {
				fill: $blue-50;
			}

			&.ibp-item-location-icon-needs-attention {
				fill: $yellow-20;
			}

			margin-right: 0.5rem;
		}
	}

	.ibp-item-location-import-label {
		@include type-style('label-01');
		color: $cool-gray-30;
	}

	.ibp-certificate-expiration-warning {
		color: $warning;

		.ibp--item-location-icon {
			fill: $warning;
		}
	}
}
