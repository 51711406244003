.ibp-audit-log-table {
	@include font-weight('regular');
	padding-top: 1rem;

	.ibp-audit-log-row {
		border-bottom: 1px solid $cool-gray-60;

		>div {
			@include type-style('body-short-01');
			display: inline-block;
			padding: 0.5rem;
			width: 40%;
		}

		>div:first-child {
			width: 60%;
		}

		.ibp-audit-log-header {
			@include font-weight('semibold');
		}
	}
}
