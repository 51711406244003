.ibp-certificate-list {
	.ibp-certificate-details {
		@include type-style('label-01');
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;

		.ibp-cert-expiry {
			color: $warning;
			padding-left: 1.25rem;
			position: relative;

			svg {
				position: absolute;
				bottom: 0px;
				left: 0px;
				fill: $warning;
				margin-right: 0.5rem;
			}
		}
	}
}
