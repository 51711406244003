.ibm .cds--data-table-content {
	.cds--overflow-menu {
		opacity: 1;
		width: 2.5rem;
		min-width: 2.5rem;
		height: 100%;
		padding: 0.4rem;
		background: none;

		&:hover {
			background-color: $cool-gray-80;
		}

		&:focus {
			background-color: $cool-gray-80;
		}

		.cds--overflow-menu__icon {
			transform: none;

			&:hover {
				fill: #8c9ba5;
			}
		}

		.cds--overflow-menu-options {
			padding: 0;
			background: #242a2e;
			margin-top: 1px;
			padding: 0;
			margin-right: 0;
			border: none;
			box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.4);

			.cds--overflow-menu-options__btn {
				white-space: normal;
			}
		}
	}
}

.cds--overflow-menu-options__option {
	height: 3rem;
	background-color: $cool-gray-80;

	&:hover {
		background-color: $cool-gray-90 !important;
	}
}

.cds--overflow-menu-options__option--disabled {
	button {
		color: #697075;
	}
}
