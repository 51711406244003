.ibp--node-details-sticky-container-content {
	// margin-top: 1.5rem;

	.ibp--node-details-sticky-header,
	.ibp--sticky-section-main-content,
	.ibp--sticky-section-callout-group-container,
	.ibp--sticky-section-quick-actions-container {
		background-color: $cool-gray-90;
	}

	.ibp--node-details-sticky-header {
		align-items: center;
		border-bottom: 1px solid $cool-gray-100;
		display: flex;
		justify-content: space-between;
		padding: 1rem;

		.ibp-node-detail-title {
			@include type-style('productive-heading-01');
			color: $white-0;
		}
	}

	.ibp--sticky-section-main-content,
	.ibp--sticky-section-callout-group-container {
		border-bottom: 1px solid $cool-gray-100;
		//min-height: 12rem;
		padding: 1rem;
	}

	.ibp--sticky-section-main-content {
		.ibp--sticky-section-group {
			padding-bottom: 1.5rem;

			.ibp--sticky-section-header {
				@include type-style('label-01');
				color: $cool-gray-30;
			}

			.ibp--sticky-section-item {
				@include type-style('body-short-01');
			}
		}
	}

	.ibp--sticky-section-quick-actions-container {
		border-top: 1px solid $cool-gray-100;
		border-bottom: 0;
		min-height: 4.625rem;
		padding: 1rem;
		margin-top: 1.25rem;

		.ibp--sticky-section-header {
			@include type-style('label-01');
			color: $cool-gray-30;
			padding-bottom: 1rem;
		}

		.ibp--sticky-section-item-button {
			@include type-style('body-short-01');
			border: 0;
			background: transparent;
			color: $blue-40;
			cursor: pointer;
			text-align: left;
			padding: 0;
		}
	}

	.ibp--sticky-section-callout-group-container {
		border-top: 1px solid $cool-gray-100;
		border-bottom: 0;
		min-height: 4.625rem;
		padding: 1rem;
		margin-top: 1.25rem;

		&.ibp--sticky-section-tx-container {
			border-top: 1px solid $action;
			border-bottom: 1px solid $action;
		}

		.ibp--sticky-section-group {
			padding-bottom: 1.5rem;

			.ibp--sticky-section-header {
				@include type-style('label-01');
				color: $cool-gray-30;
			}

			.ibp--sticky-section-item {
				@include type-style('productive-heading-03');
			}
		}
	}

	.ibp--sticky-section-callout-group-container~.ibp--sticky-section-callout-group-container {
		margin-top: 0;
	}
}

.ibp-node-detail-icons {
	display: flex;

	& button.cds--btn {
		background-color: transparent;
		margin: 0;
	}
}

.ibp-node-details-association {
	border: 0;
	display: flex;
	margin-bottom: 2px;
	padding: 1rem;
	padding-top: 1.5rem;
	background-color: $cool-gray-90;
	color: white;
	text-align: left;
	transition: $basic-duo-transition;
	cursor: pointer;
	width: 100%;

	&.ibp-error-node-not-associated-container {
		border: 1px solid $red-50;
	}

	&:hover {
		background-color: $cool-gray-80;
	}

	&:focus {
		box-shadow: inset 0px 0px 0px 2px white;
		outline: 0;
	}

	.ibp-identity-information {
		flex: 1;

		p {
			@include type-style('body-short-01');
			color: $cool-gray-30;
		}
	}

	.ibp-identity-icon {
		margin-top: auto;
		margin-bottom: auto;
	}
}
