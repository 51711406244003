.ibp-wizard-step {
	@include font-weight('semibold');
	color: $cool-gray-30;
	margin-bottom: 1rem;
}

.ibp-wizard-title {
	@include type-style('productive-heading-05');
	margin-bottom: 2rem;

	.ibp--tooltip__label {
		@include type-style('productive-heading-05');
		font-size: 1.65rem;
	}
}

.hidden_section {
	display: none;
}

.ibp-wizard-step-desc {
	margin-bottom: 2rem;
}

.ibp-wizard-step-header {
	@include type-style('productive-heading-04');
	align-items: baseline;
	display: flex;
	padding-top: 1rem;

	.ibp--tooltip__label {
		@include type-style('productive-heading-04');
		font-size: 1.4rem;
	}
}

.ibp-tooltip-text.cds--tooltip {
	@include type-style('body-short-01');
	max-width: 25rem;
}

.ibp-link {
	cursor: pointer;
	margin-bottom: 1.5rem;
}

.ibp-wizard-secondary-step .ibp-modal-desc {
	display: none;
}

.ibp-wizard-first-step .ibp-modal-desc {
	display: block;
}

/*.visible_section {
	p,
	a {
		@include type-style('body-short-02');
	}
}*/

.ibp-panel--content {

	.ibp-wizard-first-step,
	.ibp-wizard-secondary-step {
		.ibp-wizard-loading {
			.cds--loading__svg {
				.cds--loading__stroke {
					stroke: $blue-60;
				}
			}
		}
	}
}

.ibp-wizard-step-header-desc {
	.ibp-wizard-link-spacer {
		height: 1rem;
	}
}

.ibp-wizard-loading {
	margin-top: 2rem;
}
