.ibp-user-info-header-icon {
	bottom: 2px;
	fill: $white-0;
	position: relative;
}

.ibp-title-bar-login {
	display: flex;
	height: 3rem;
	position: relative;
	flex-grow: 0;

	.ibp-header-icon-button-container {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 3rem;

		&:hover {
			background-color: $cool-gray-90;
		}

		&.ibp-header-active-item {
			&:before {
				background-color: $action;
				bottom: 0;
				content: '';
				height: 0.25rem;
				position: absolute;
				width: 3rem;
			}
		}

		&.ibp-header-user-info-button {
			background-color: transparent;
			border: 0;
			cursor: pointer;
			height: inherit;
			outline: 0;
			padding: 0;
			transition: $basic-duo-transition;

			&:hover,
			&:focus {
				background: $itemHover;
			}

			&:focus {
				border: 2px solid $white;
				box-shadow: inset 0px 0px 0px 1px $cool-gray-100;
				outline: 0;
			}
		}
	}
}

.ibp-user-info-detail-container {
	background-color: $cool-gray-90;
	border-top: 1px solid $itemBackground;
	padding: 1rem;
	position: fixed;
	right: 0;
	top: 3rem;
	width: 16.25rem;

	.ibp-user-info-username {
		@include type-style('body-short-01');
		@include font-weight('semibold');
		color: $cool-gray-20;
	}

	.ibp-header-user-info-subtext {
		@include type-style('body-short-01');
		color: $cool-gray-20;
	}

	.ibp-user-info-detail-content {
		margin-bottom: 1.5rem;
	}

	.ibp-header-user-info-logout {
		border-bottom: 1px solid $cool-gray-80;
		border-top: 1px solid $cool-gray-80;
		color: $white;
		display: block;
		line-height: 2rem;
		padding: 0.5rem;
		text-decoration: none;
		transition: $basic-duo-transition;

		&:hover,
		&:focus {
			background-color: $itemHover;
		}

		&:focus {
			box-shadow: inset 0px 0px 0px 2px $white;
			outline: 0;
		}
	}
}

.ibp-user-info-detail-container-longer {
	min-height: 10rem;
}
