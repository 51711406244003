.ibp-tooltip-container {
	display: flex;
	height: fit-content;
	align-items: center;

	&.ibp-tooltip-container-with-checkbox {
		align-items: center;
		display: flex;
		height: 1.5rem;
		margin-bottom: 0.1875rem;
		margin-top: 0.1875rem;
	}
}

.ibp-dark-theme .cds--tooltip .cds--tooltip__caret {
	background-color: transparent;
}

.ibp-tooltip-container {
	// .cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--top.cds--tooltip--align-start::before {
	// 	top: -0.27rem;
	// 	z-index: $highestLayer;
	// }

	// .cds--tooltip__trigger.cds--tooltip__trigger--definition.cds--tooltip--bottom.cds--tooltip--align-start::before {
	// 	bottom: -0.27rem;
	// 	z-index: $highestLayer;
	// }

	// .cds--tooltip--definition {
	// 	.cds--tooltip__trigger {
	// 		text-align: left;
	// 	}
	// }

	.cds--popover-content {
		p {
			padding: 4px;
			font-size: .875rem;
			font-weight: 400;
			line-height: 1.28572;
			letter-spacing: .16px;
			text-align: left;
		}

	}
	.cds--tooltip-trigger__wrapper {
		.cds--btn--ghost {
			&:focus {
				border: none;
				box-shadow: none;
			}
		}
	}
}

// .cds--tooltip[data-floating-menu-direction='right'] {
// 	max-width: 25rem;
// }

.ibp-hide-tooltip {
	display: none !important;
	pointer-events: none;
}
