.ibp-nodes-welcome {
	@include type-style('body-short-01');
}

.ibp-nodes-welcome-bar {
	width: 100%;
	background: $itemBackground;
	border-top: 1px solid $background;
	min-height: 3rem;
	display: flex;
	justify-content: flex-end;
}

.ibp-nodes-welcome-btn {
	margin-left: 0.125rem;
	margin-bottom: 0.5px;
	text-align: left;
	width: 15rem;
	padding: 1rem;
	background: $action;
	display: inline-block;
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	right: 0;
	text-decoration: none;
	color: #ffffff;
}

.ibp-nodes-welcome-btn-docs {
	margin-left: 0.125rem;
	margin-bottom: 0.5px;
	margin-right: 0.125rem;
	text-align: left;
	width: 9rem;
	padding: 1rem;
	background: $blue-80;
	display: inline-block;
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	text-decoration: none;
	color: #ffffff;
}

.with-btn {
	right: 15rem;
}

.doc-only {
	right: 0;
}

.ibp-nodes-welcome-btn-docs:hover {
	background: $blue-90;
}

.ibp-nodes-welcome-btn:hover {
	background: $actionHover;
}

.ibp-nodes-welcome-close-btn {
	cursor: pointer;
}

.ibp-nodes-welcome-close-btn-svg {
	height: 0.8rem;
}

.ibp-nodes-welcome-collapsed {
	align-items: center;
	background: $itemBackground;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
	padding-right: 1rem;
	padding-top: 1rem;
	position: relative;
	width: 100%;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 0.25rem;
		background: linear-gradient(to right, $blue-80, $blue-60);
	}
}

.ibp-nodes-welcome-container {
	background: $itemBackground;
	min-height: 7rem;
	border-top: 1px solid $background;
	padding-left: 1rem;
	padding-top: 1rem;
	position: relative;
}

.ibp-nodes-welcome-container-head {
	margin-bottom: 1.5rem;
	display: inline-block;
	padding-left: 0.5rem;
}

.ibp-nodes-welcome-head {
	@include type-style('productive-heading-05');
}

.ibp-nodes-welcome-head-collapsed {
	margin-left: 1rem;
}

.ibp-nodes-welcome-container-desc {
	@include type-style('body-short-01');
	display: block;
	max-width: 40rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
}

.ibp-nodes-welcome-desc {
	@include type-style('body-short-01');
	margin: 0rem 0rem 1rem 1rem;
	width: 38rem;

	.ibp-beta-close-date {
		@include font-weight('semibold');
	}
}

.ibp-nodes-welcome-docs-link {
	display: block;
	padding-top: 0.5rem;
}

.ibp-nodes-welcome-main {
	align-items: center;
	background: $itemBackground;
	display: flex;
	min-height: 13.125rem;
	position: relative;
	justify-content: space-between;
	width: 100%;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 0.25rem;
		background: linear-gradient(to right, $blue-80, $blue-60);
	}

	.partyPopperIcon {
		margin-right: 4rem;
		max-height: 160px;
		max-width: 160px;
	}

	.ibp-ga-welcome-desc {
		padding-right: 0.5rem;
	}
}

.ibp-nodes-welcome-start-btn {
	width: 25rem;
	min-height: 3rem;
	border-right: 1px solid $background;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	background-color: $action;
	border: 0;
	padding: 0;
	padding-left: 1rem;
	transition: $basic-duo-transition;
	color: $text;
	border: 2px solid transparent;

	& * {
		@include type-style('body-short-01');
	}

	&:hover {
		background-color: $actionHover;
	}

	&:focus {
		border: 2px solid $white;
		box-shadow: inset 0px 0px 0px 1px $cool-gray-100;
		outline: 0;
	}

	.ibp-nodes-welcome-start-expand {
		margin-right: 1rem;
		cursor: pointer;

		.launchIcon {
			width: 1.25rem;
		}
	}
}

.ibp-nodes-welcome-start-message {
	@include type-style('label-01');
	width: 23rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.ibp-nodes-welcome-start-expand-2 {
	display: inline-block;
	cursor: pointer;
}

.ibp-nodes-welcome-start-text {
	display: inline-block;
	padding-right: 1rem;
}

.ibp-welcome-step-number {
	display: inline-block;
	width: 1rem;
}

.start-bar-exp {
	padding-top: 0.875rem;
	padding-left: 1rem;
	cursor: pointer;
}

.flip {
	transform: rotate(180deg);
}

.active {
	background: $cool-gray-80;
}

//----------Animations----------

//---Start
@keyframes welcomeExpansionStart {
	from {
		height: 0rem;
	}

	to {
		height: 9rem;
	}
}

@keyframes welcomeCollapseStart {
	from {
		height: 9rem;
	}

	to {
		height: 0rem;
	}
}

.welcome-expand-start {
	overflow: hidden;
	animation: welcomeExpansionStart ease 0.5s;
}

.welcome-collapse-start {
	height: 0rem;
	overflow: hidden;
	animation: welcomeCollapseStart ease 0.5s;
}

//---Build
@keyframes welcomeExpansionBuild {
	from {
		height: 0rem;
	}

	to {
		height: 45.75rem;
	}
}

@keyframes welcomeCollapseBuild {
	from {
		height: 45.75rem;
	}

	to {
		height: 0rem;
	}
}

.welcome-expand-build {
	overflow: hidden;
	animation: welcomeExpansionBuild ease 0.5s;
}

.welcome-collapse-build {
	height: 0rem;
	overflow: hidden;
	animation: welcomeCollapseBuild ease 0.5s;
}

//---Join
@keyframes welcomeExpansionJoin {
	from {
		height: 0rem;
	}

	to {
		height: 26.4rem;
	}
}

@keyframes welcomeCollapseJoin {
	from {
		height: 26.4rem;
	}

	to {
		height: 0rem;
	}
}

.welcome-expand-join {
	overflow: hidden;
	animation: welcomeExpansionJoin ease 0.5s;
}

.welcome-collapse-join {
	height: 0rem;
	overflow: hidden;
	animation: welcomeCollapseJoin ease 0.5s;
}

//---Deploy
@keyframes welcomeExpansionDeploy {
	from {
		height: 0rem;
	}

	to {
		height: 28.9rem;
	}
}

@keyframes welcomeCollapseDeploy {
	from {
		height: 26.4rem;
	}

	to {
		height: 0rem;
	}
}

.welcome-expand-deploy {
	overflow: hidden;
	animation: welcomeExpansionDeploy ease 0.5s;
}

.welcome-collapse-deploy {
	height: 0rem;
	overflow: hidden;
	animation: welcomeCollapseDeploy ease 0.5s;
}

.ibp-nodes-welcome-header-container {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 1.75rem 1rem 1.25rem 1rem;
}
